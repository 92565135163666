exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-definicje-js": () => import("./../../../src/pages/definicje.js" /* webpackChunkName: "component---src-pages-definicje-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-opowiesci-js": () => import("./../../../src/pages/opowiesci.js" /* webpackChunkName: "component---src-pages-opowiesci-js" */),
  "component---src-pages-poradnik-js": () => import("./../../../src/pages/poradnik.js" /* webpackChunkName: "component---src-pages-poradnik-js" */),
  "component---src-pages-reprezentacja-js": () => import("./../../../src/pages/reprezentacja.js" /* webpackChunkName: "component---src-pages-reprezentacja-js" */),
  "component---src-pages-reprezentacja-markdown-remark-representation-parent-markdown-remark-frontmatter-category-js": () => import("./../../../src/pages/reprezentacja/{MarkdownRemarkRepresentation.parent__(MarkdownRemark)__frontmatter__category}.js" /* webpackChunkName: "component---src-pages-reprezentacja-markdown-remark-representation-parent-markdown-remark-frontmatter-category-js" */),
  "component---src-templates-definition-index-js": () => import("./../../../src/templates/Definition/index.js" /* webpackChunkName: "component---src-templates-definition-index-js" */)
}

